<template>
	<div class="cart" v-loading="loading">
		<!-- 领券中心 -->
		<template>
			<div class="content-div" v-if="couponList.length > 0">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">{{$lang("common.coupons")}}</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>{{$lang("common.more")}}</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>
					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color">
								{{ siteInfo.price_unit }}
								<span>{{ item.money }}</span>
							</p>
							<p class="coupon-term">
								<span>{{ item.coupon_name }}</span>
							</p>
							
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">{{$lang("common.get")}}</span>
								<span v-else>去使用</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>
		</template>

		<template v-if="cartList.length || invalidGoods.length">
			<nav>
				<li>
					<el-checkbox v-model="checkAll" @change="allElection"></el-checkbox>
				</li>
				<li>{{$lang('goods_item')}}</li>
				<li>{{$lang('price')}}</li>
				<li>{{$lang('quantity')}}</li>
				<li>{{$lang('subtotal')}}</li>
				<li>{{$lang('operation')}}</li>
			</nav>
			<div class="list" v-for="(siteItem, siteIndex) in cartList" :key="siteIndex">
				<div class="item">
					<!-- <div class="head">
						<el-checkbox v-model="siteItem.checked" @change="siteAllElection(siteIndex)"></el-checkbox>
						<router-link to="/shop" target="_blank">{{ siteItem.siteName }}</router-link>
						<el-tag size="small" v-if="siteItem.cartList[0].is_own == 1">自营</el-tag>
					</div> -->
					<ul v-for="(item, cartIndex) in siteItem.cartList" :key="cartIndex">
						<li>
							<el-checkbox v-model="item.checked" @change="singleElection(siteIndex, cartIndex)"></el-checkbox>
						</li>
						<li class="goods-info-wrap" @click="$router.pushToTab({ path: '/product-' + item.goods_id + '.html' })">
							<div class="img-wrap"><img class="img-thumbnail" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(siteIndex, cartIndex)" /></div>
							<div class="info-wrap">
								<h5>{{ item.sku_name }}</h5>
								<template v-if="item.sku_spec_format">
									<span v-for="(x, i) in item.sku_spec_format" :key="i">
										{{ x.spec_name }}：{{ x.spec_value_name }} {{ i < item.sku_spec_format.length - 1 ? '；' : '' }}
									</span>
								</template>
							</div>
						</li>
						<li>
							<span>{{ siteInfo.price_unit + item.discount_price }}</span>
						</li>
						<li>
							<el-input-number v-model="item.num" :step="modifyNum" size="mini" :min="1" :max="item.stock" @change="cartNumChange($event, { siteIndex, cartIndex })"></el-input-number>
						</li>
						<li>
							<strong class="subtotal ns-text-color" v-if="item.discount_price * item.num">{{ siteInfo.price_unit + $util.filterPrice(item.discount_price * item.num) }}</strong>
							<strong class="subtotal ns-text-color" v-else>{{ siteInfo.price_unit }}0</strong>
						</li>
						<li>
							<el-button type="text" @click="deleteCart(siteIndex, cartIndex)">{{$lang('common.delete')}}</el-button>
						</li>
					</ul>
				</div>
			</div>

			<div class="lose-list" v-if="invalidGoods.length">
				<div class="head">
					{{$lang('expired_goods')}}
					<span class="ns-text-color">{{ invalidGoods.length }}</span>
					{{$lang('piece')}}
				</div>
				<ul v-for="(goodsItem, goodsIndex) in invalidGoods" :key="goodsIndex">
					<li>
						<el-tag size="small" type="info">失效</el-tag>
					</li>
					<li class="goods-info-wrap">
						<div class="img-wrap"><img class="img-thumbnail" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageErrorInvalid(goodsIndex)" /></div>
						<div class="info-wrap">
							<h5>{{ goodsItem.sku_name }}</h5>
							<template v-if="goodsItem.sku_spec_format">
								<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">
									{{ x.spec_name }}：{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '；' : '' }}
								</span>
							</template>
						</div>
					</li>
					<li>
						<span>{{ siteInfo.price_unit + goodsItem.discount_price }}</span>
					</li>
					<li>{{ goodsItem.num }}</li>
					<li>
						<strong class="subtotal">{{ siteInfo.price_unit + $util.filterPrice(goodsItem.discount_price * goodsItem.num) }}</strong>
					</li>
				</ul>
			</div>

			<footer>
				<el-checkbox v-model="checkAll" @change="allElection">{{$lang('select_all')}}</el-checkbox>
				<ul class="operation">
					<li>
						<el-button type="text" @click="deleteCartSelected">{{$lang('common.delete')}}</el-button>
					</li>
					<li>
						<el-button type="text" @click="clearInvalidGoods" v-if="invalidGoods.length!=0">{{$lang('clear_cart')}}</el-button>
					</li>
				</ul>
				<div class="sum-wrap">
					<div class="selected-sum">
						<span>{{$lang('select_items')}}</span>
						<em class="total-count">{{ totalCount }}</em>
						<span>{{$lang('piece')}}</span>
					</div>
					<div class="price-wrap">
						<span>{{$lang('total_amount')}}：</span>
						<strong class="ns-text-color">{{ siteInfo.price_unit + totalPrice }}</strong>
					</div>

					<el-button type="primary" v-if="totalCount != 0" @click="settlement">{{$lang('common.settlement')}}</el-button>
					<el-button type="info" v-else disabled @click="settlement">{{$lang('common.settlement')}}</el-button>
				</div>
			</footer>
		</template>
		<div class="empty-wrap" v-else-if="!loading && (!cartList.length || !invalidGoods.length)">
			<router-link to="/">{{$lang('empty_cart')}}</router-link>
		</div>
	</div>
</template>

<script>
	import cart from './cart';
	export default {
		name: 'cart',
		mixins: [cart]
	};
</script>
<style lang="scss" scoped>
	@import './cart.scss';
</style>
